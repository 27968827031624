<template>
    <v-row class="mt-5 mb-5">
        <v-col md="8">
            <v-row dense>
                <v-col md="12">
                    <v-text-field label="Name" outlined readonly dense :value="'web' + webspace.id + '.' + webspace.host" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Domains" outlined readonly dense :value="webspace.domains" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Speicher" outlined readonly dense :value="webspace.disk + ' GB'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Datenbanken" outlined readonly dense :value="webspace.databases" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="E-Mail Postfächer" outlined readonly dense :value="webspace.mailboxes" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Plesk Benutzer" outlined readonly dense :value="webspace.username" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Plesk Passwort" outlined readonly dense :value="webspace.password" @click="hidePassword = !hidePassword" :type="hidePassword ? 'password' : 'text'" />
                </v-col>
                <v-col md="12">
                    <v-text-field label="Restlaufzeit" outlined readonly dense :value="remainingTime" />
                </v-col>
            </v-row>
        </v-col>
        <v-col md="4">
            <v-btn block class="mb-2" color="info" @click="pleskLogin()">Plesk Login</v-btn>
            <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
            <WebspaceUpgrade :webspace="webspace" :service="service" />
        </v-col>
    </v-row>
</template>

<script>
import interval from '../../../util/interval'
import xeonserv from '../../../apis/xeonserv'
import WebspaceUpgrade from './WebspaceUpgrade'

export default {
    components: { WebspaceUpgrade },
    data() {
        return {
            now: new Date(),
            hidePassword: true
        }
    },
    timers: {
        updateNow: { time: 1000, autostart: true, repeat: true, callback() { this.now = new Date() }}
    },
    props: {
        webspace: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    computed: {
        displayStatus() {
            if(this.service.expired)
                return 'Abgelaufen'
            return 'Online'
        },
        remainingTime() {
            this.now;
            return interval.format(interval.until(this.service.expire_at))
        }
    },
    methods: {
        pleskLogin() {
            xeonserv().post('/webspaces/' + this.webspace.id + '/session').then(res => {
                window.open('https://' + this.webspace.host + ':8443/enterprise/rsession_init.php?PLESKSESSID=' + res.data.data)
            })
        }
    }
}
</script>