<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Allgemein
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <WebspaceGeneral :webspace="webspace" :service="service" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import WebspaceGeneral from './WebspaceGeneral'

export default {
    components: { WebspaceGeneral },
    data() {
        return {
            tab: 0,
            webspace: {
                id: this.$route.params.id,
                domains: 0,
                disk: 0,
                databases: 0,
                mailboxes: 0,
                username: '',
                password: '',
                host: ''
            },
            service: {
                id: 0,
                expired: false
            }
        }
    },
    created() {
        xeonserv().get('/webspaces/' + this.webspace.id).then(res => this.webspace = res.data.data)
        xeonserv().get('/webspaces/' + this.webspace.id + '/service').then(res => {
            console.log(res)
            this.service = res.data.data
        })
        this.$on('setwebspace', webspace => this.webspace = webspace)
    }
}
</script>