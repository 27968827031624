<template>
    <v-dialog max-width="600px" v-model="open">
        <template v-slot:activator="{ on, attrs }">
            <v-btn block class="mb-2" color="primary" v-bind="attrs" v-on="on">Upgraden</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Webspace Upgrade</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    Konfigurierbare Domains
                    <v-slider v-model="domainsOption" min="0" :max="domainsValues.length - 1" :tick-labels="domainsValues" tick-size="0" />
                    Speicher
                    <v-slider v-model="diskOption" min="0" :max="diskValues.length - 1" :tick-labels="diskValues.map(e => e + ' GB')" tick-size="0" />
                    Datenbanken
                    <v-slider v-model="databasesOption" min="0" :max="databasesValues.length - 1" :tick-labels="databasesValues" tick-size="0" />
                    E-Mail Postfächer
                    <v-slider v-model="mailboxesOption" min="0" :max="mailboxesValues.length - 1" :tick-labels="mailboxesValues" tick-size="0" />
                    <div class="text-center mt-3 mb-3" style="font-size: 25px; font-weight: 500;">
                        Preis: {{ price.toFixed(2).replace('.', ',') }} €
                    </div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="open = false">Abbrechen</v-btn>
                <v-btn v-if="price >= 0" @click="upgrade()" color="primary">Kostenpflichtig Upgraden</v-btn>
                <v-btn v-else @click="upgrade()" color="primary">Downgraden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

const DOMAINS_OPTIONS = [1,3,5,10,25];
const DISK_OPTIONS = [1,3,5,10,20,30,40,50,100];
const DATABASES_OPTIONS = [3,5,10,15];
const MAILBOXES_OPTIONS = [10,20,30,50,100];

export default {
    props: {
        webspace: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    data() {
        return {
            open: false,
            pricing: {
                base: 0,
                domains: 0,
                disk: 0,
                databases: 0,
                mailboxes: 0
            },
            domainsValues: DOMAINS_OPTIONS.filter(v => v >= this.webspace.domains),
            diskValues: DISK_OPTIONS.filter(v => v >= this.webspace.disk),
            databasesValues: DATABASES_OPTIONS.filter(v => v >= this.webspace.databases),
            mailboxesValues: MAILBOXES_OPTIONS.filter(v => v >= this.webspace.mailboxes),
            domainsOption: 0,
            diskOption: 0,
            databasesOption: 0,
            mailboxesOption: 0
        }
    },
    created() {
        xeonserv().get('/pricing/WEBSPACE').then(res => this.pricing = res.data.data)
    },
    computed: {
        domains() {
            return this.domainsValues[this.domainsOption]
        },
        disk() {
            return this.diskValues[this.diskOption]
        },
        databases() {
            return this.databasesValues[this.databasesOption]
        },
        mailboxes() {
            return this.mailboxesValues[this.mailboxesOption]
        },
        price() {
            const duration = (new Date(this.service.expire_at).getTime() - new Date().getTime()) / 86400000
            const domainsDiff = this.domains - this.webspace.domains
            const diskDiff = this.disk - this.webspace.disk
            const databasesDiff = this.databases - this.webspace.databases
            const mailboxesDiff = this.mailboxes - this.webspace.mailboxes
            return ((domainsDiff * this.pricing.domains) + (diskDiff * this.pricing.disk) + (databasesDiff * this.pricing.databases) + (mailboxesDiff * this.pricing.mailboxes)) * (duration / 30)
        }
    },
    methods: {
        upgrade() {
            xeonserv().put('/webspaces/' + this.webspace.id, {
                domains: this.domains,
                disk: this.disk,
                databases: this.databases,
                mailboxes: this.mailboxes
            }).then(res => {
                this.$parent.$parent.$parent.$parent.$emit('setwebspace', res.data.data)
                this.$store.dispatch('loadCurrentUser')
                this.open = false
            })
        }
    },
    watch: {
        webspace() {
            if(this.webspace.domains > 0) {
                this.domainsValues = DOMAINS_OPTIONS.filter(v => v >= this.webspace.domains)
                this.domainsOption = 0
            }
            if(this.webspace.disk > 0) {
                this.diskValues = DISK_OPTIONS.filter(v => v >= this.webspace.disk)
                this.diskOption = 0
            }
            if(this.webspace.databases > 0) {
                this.databasesValues = DATABASES_OPTIONS.filter(v => v >= this.webspace.databases)
                this.databasesOption = 0
            }
            if(this.webspace.mailboxes > 0) {
                this.mailboxesValues = MAILBOXES_OPTIONS.filter(v => v >= this.webspace.mailboxes)
                this.mailboxesOption = 0
            }
        }
    }
}
</script>